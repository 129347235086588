
.d-inline-block {
  display: inline-block;
  overflow: visible;
  overflow: unset;
}

.screen-reader-text {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
