body.home.custom-background #content {
  margin-top: 440px;
}

@media (max-width: 767px) {
  body.home #content {
    margin-top: 220px;
  }
}

#content {
  background-color: $white;
  margin-top: 220px;
  min-height: 400px;
  padding-bottom: 4em;

  .article-body {
    margin-bottom: 40px;
  }
}

%content-links {
  a {
    &:link,
    &:visited {
      color: $spd_red;
    }

    &:hover,
    &:focus,
    &:active {
      color: $darker_red;
    }
  }
}

.tool-pagination {
  text-align: center;
  margin-top: 20px;
  font-size: 0;

  & > * {
    display: inline-block;
  }

  ul.pages {
    padding: 0;
    margin: 0;
    font-size: 0;
    > * {
      font-size: 15px;
    }

    li {
      &, & > * {
        display: inline-block;
      }

      .page-number {
        padding: 5px 3px 1px;
        width: 31px;
        height: 31px;

        &.current {
          font-weight: bold;
        }
      }

      &:not(:last-child) .page-number {
          margin-right: 10px;
      }
    }
  }

  a {
    background-color: #f0f0f0;

    &.prev, &.next {
      padding: 5px 2px 1px 2px;
      height: 31px;
      min-width: 31px;
      letter-spacing: -1px;
      font-size: 15px;
    }

    &.prev {
      margin-right: 10px;
    }

    &.next {
      margin-left: 10px;
    }
  }

  a:hover {
    background-color: #f8f8f8;
  }
}

// #content {
//   a:link, a:visited, a:active, a:hover {
//     color: $dark_grey;
//   }
//
//   a:focus {
//     color: $dark_grey;
//   }
// }
