body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  line-height: 145%;
}

h1, h2, h3, h4, h5, h1.h1, h2.h2, h3.h3, h4.h4, h5.h5 {
  font-weight: normal;
  line-height: 135%;

  a:link,
  a:visited {
    color: $black;
  }
}
