.has-top-bar section#content.content{
  margin-top:152px;
}
.has-top-bar section#content.content .top-bar div {
  margin:auto;
}
@media (min-width: 769px) and (max-width: 1024px) {
 .has-top-bar section#content.content{
    margin-top:152px;
  }
}
@media (min-width: 415px) and (max-width: 768px) {
 .has-top-bar section#content.content{
    margin-top:81px;
  }
}
@media (min-width: 376px) and (max-width: 414px) {
 .has-top-bar section#content.content{
    margin-top:81px;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
 .has-top-bar section#content.content{
    margin-top:81px;
  }
}