@media (max-width: 767px) {
  table.em-calendar {
    td {
      font-size: 1.4em;
      padding: 10px 12px;
    }
  }
}

table.em-calendar { // FIXME: change prefix "em-"
  width: 100%;
  border-collapse: separate;
  border-spacing: 4px;
  // TODO
  // Liberation Sans looks better but has very different metrics, so vertical
  // centering breaks.
  // font-family: 'Liberation Sans';

  tbody td {
    // padding: 3px 3px;
    padding: 4px 3px 2px 3px;
    border-radius: 1px;
    vertical-align: middle;
    line-height: normal;
    background-color: transparent;


    /* &.eventless,
    &.eventless-today {
    } */

    &.eventless {
      background-color: #f0f0f0;
      color: #444;
    }

    &.eventless-today {
      background-color: #888;
      color: #000;
    }

    &.eventful,
    &.eventful-today {
      background-color: red; // FIXME
    }

    &::after,
    &::before {
      content: '';
      display: inline-block;
      margin-top: 100%;
      vertical-align: top;
      // width: 1px;
    }

    /*
    &::before {
      // margin-left: -1px;
    }
    &::after {
      // margin-right: -1px;
    }
    */
  }
}
