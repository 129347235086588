.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  
  li {
    margin: 0;
    padding: 0;
  }
}
