table.em-calendar {
  td.eventful {
    a {
      background-color: $spd_red;
      border-radius: 2px;
      color: #fff;
      padding: 2px 3px;
    }
  }

  td.eventless-today,
  td.eventful-today {
    border-radius: 2px;
  }
}

table#wp-calendar,
table.em-calendar {
  width: 100%;
  border-collapse: separate;
  border-spacing: 4px;
  // TODO
  // Liberation Sans looks better but has very different metrics, so vertical
  // centering breaks.
  // font-family: 'Liberation Sans';

  thead th {
    text-align: center;
    vertical-align: middle;
    line-height: normal;
  }

  tbody td {
    // padding: 3px 3px;
    padding: 5px 3px 1px 3px;
    border-radius: 1px;
    vertical-align: middle;
    line-height: normal;
    background-color: #f0f0f0;
    color: #444;
    text-align: center;

    &.pad {
      background-color: transparent;

      &::after,
      &::before {
      margin-top: 0;
      // display: none !important;
      }
    }

    /* &.eventless,
    &.eventless-today {
    } */

    &.eventless {
      background-color: #f0f0f0;
      color: #444;
    }

    &.eventless-today,
    &#today {
      background-color: #888;
      color: #000;
    }

    &.eventful,
    &.eventful-today {
      background-color: red; // FIXME
    }

    &::after,
    &::before {
      content: '';
      display: inline-block;
      margin-top: 100%;
      vertical-align: top;
      // width: 1px;
    }

    /*
    &::before {
      // margin-left: -1px;
    }
    &::after {
      // margin-right: -1px;
    }
    */
  }

  tfoot {
    display: none;
  }
}
