.event-list {
  li.event {
    margin: 0 0.5em 1.5em 0;
  }

  .event-content {
    font-size: 0.9em;
    margin-top: 1.0em;
  }

  .event-title h3 {
    font-size: 1.1em;
    hyphens: auto;
    word-wrap: break-word;
    word-break: break-word;
  }

  .event-date {
    margin-right: 0.75em;
  }

  .start-date {
    background-color: $spd_red;
    color: #fff;
    border-radius: 2px;
    padding: 6px;
    width: 60px;
  }

  .event-time {
    font-weight: normal;
    color: #999;
  }
}

.layout-sidebar {
  .event-list {
    .event-title {
      a {
        word-break: break-all;
      }
    }
  }
}
