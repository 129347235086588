a {
  transition: .2s;

  &:link,
  &:visited {
    color: $black;
  }

  &:hover,
  &:focus,
  &:active {
    color: $spd_red;
    text-decoration: none;
  }
}
