#staff-directory-wrapper .single-staff {
  padding-bottom: 15px;
  margin-bottom: 10px;

  img {
    float: left;
    margin-right: 10px;
    padding: 1px;
    border: 1px solid $mid_grey;
  }

  .name {
    @extend .h2;
  }

  .position {
    font-style: italic;
  }

  .bio {
    font-size: smaller;
  }
}
