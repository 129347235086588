%form-input {
  transition: .2s;
  outline: none;
  padding: .5em;
}

%button,
button,
input[type="submit"] {
  @extend %form-input;
  background-color: $spd_red;
  border: 1px solid $spd_red;
  color: #fff;

  &:hover {
    background-color: $darker_red;
    border: 1px solid $darker_red;
  }
}

%input-text,
input[type="text"] {
  @extend %form-input;
  border: 1px solid #ddd;

  &:hover {
    border: 1px solid $mid_grey;
  }

  &:active,
  &:focus {
    border: 1px solid $spd_red;
  }
}
