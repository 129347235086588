.tease {
  @extend %content-links;
  clear: both;
  margin-bottom: 40px;

  &:first-child {
    margin-top: 55px;
  }

  .h2 {
    margin: 0 0 .5em;

    a:hover {
      color: $spd_red;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  img.featured {
    border: 1px solid $mid_grey;
    margin-bottom: .5em;

    &.featured-large {
      margin-bottom: 1em;
      width: 100%;
    }
  }

  img.pull-left {
    margin-right: 1.5em;
  }

  img.pull-right {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .tease img.pull-left {
    max-width: 50%;
  }
}
